import React from 'react'
import "./mystyles.scss"
import Layout from '../components/Layout'
import ServicesCards from "../components/ServicesCards"
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

export default function toptalent() {
    return (
        <Layout>
            <SEO />
            <section className="hero">
                <div className="hero-body">
                    {/* <p className="title has-text-primary">Top Talent</p> */}
                    <div className="columns">
                        <div className="column pl-6 is-one-third">
                        <StaticImage 
                            src="../images/TopTalentLogo-green.png" 
                            alt="Top Talent and pyramid logo" 
                            width={400}
                        />
                        </div>
                        <div className="column has-text-light">
                            <p className="p-4 has-text-light">Top Talent is located in Northern New Jersey, just 20 miles west of New York City. All our services are available in-person and virtually within the United States and globally.</p>
                            <p className="p-4">Founder and principal, Judy McHugh, started Top Talent’s coaching, leadership development and talent/performance management consulting business because she has a passion for supporting other people’s personal and career growth, improving their performance and that of the organization’s they lead. </p>
                            <p className="p-4">“I’m proud of the amazing relationships I’ve cultivated and the outstanding results I’ve seen my clients achieve”.</p>
                            <p className="p-4">“Coaching clients often say that I guide them through the process of discovering the best within themselves, providing them with a new perspective that positively impacts their personal and professional lives on a sustainable basis. “</p>
                            <p className="p-4">“Talent, performance management and leadership development consulting clients are delighted with the practices and programs Top Talent creates, producing a pipeline of leaders ready for challenging responsibilities and succession”.</p>
                        </div>
                    </div>
                    <div className="has-text-primary">
                        <p className="py-3 is-size-4">"Judy is an amazing coach. She holds space for her clients and has all the patience in the world to enable them to collect their thoughts, while helping them process through things. She guided me through making big decisions like creating my own business and making bold moves. I never would have gotten there if it wasn't for her."</p>
                        <p className="py-3 pl-6 has-text-weight-bold">Tatyana Fittipaldi , MBA, Change Management Strategist and former Finance Executive</p>
                        <p className="py-3 pt-6 is-size-4">"Judy has a grounded and straight-forward approach to coaching, while being a tremendous partner to discover emotional depths that support driving one toward their objectives. The energy leadership assessment, tools and debrief were very enlightening, providing me with great insights. I'm incredibly grateful to have had Judy as a coach, and am now well on my way to achieving my goals. Thanks Judy!"</p>
                        <p className="py-3 pl-6 has-text-weight-bold">Amy Moczyemba, Executive Coach and Compensation Consultant</p>
                        <p className="py-3 pt-6 is-size-4">"Judy is a thought leader who takes the initiative on designing and implementing innovative processes and tools and freely sharing them across the organization. She is a collaborative member of the global talent community at Linde, in addition to heading up all aspects of talent management in the America’s region. Judy quickly builds relationships with business colleagues and leadership teams. She spent over a year working with global colleagues to plan cultural transformation as part of the company’s pre-merger activities. This work included developing organizational behaviors, designing a completely new performance management process, and building a senior leadership workshop to support the mission and strategic direction of the new company. Judy has at lot to offer and I look forward to the opportunity of partnering with her in the future."</p>
                        <p className="py-3 pl-6 has-text-weight-bold">Michael Heil, GPHR, Retired Head of Talent Management at Linde</p>
                    </div>
                </div>
            </section>
            <ServicesCards />
        </Layout>
    )
}
