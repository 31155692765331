import React from 'react'
import { Link } from 'gatsby'
import "../pages/mystyles.scss"
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from "../styles/style.css"

export default function ServicesCards() {
    return (
        <section className="section">
            <div className="columns is-8">
              <div className="column">
                <div className="card has-background-light has-text-centered">
                  <div className="card-image">
                    <figure className="image">
                      <StaticImage 
                        src="../images/two-woman-chatting-1311518.jpg"
                        alt="Two women chatting"
                        placeholder="blurred"
                      />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title">Coaching</p>
                      </div>
                    </div>
                    <div className="content">
                      <p className="has-text-left">Using the cutting-edge iPEC Core Energy Leadership™ framework, Shirzad Chamine's Positive Intelligence Program and research-backed metrics, our coaching leverages high-performance tools to reveal a deeper level of consciousness, optimizing your success. The process has created sustainable results for thousands of individual contributors, as well as managers, executives and their teams.</p>
                      <Link to="/coaching" className="button is-multiline has-background-primary has-text-light has-text-weight-bold">Learn more about Coaching</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="card has-background-light has-text-centered">
                  <div className="card-image">
                    <figure className="image">
                      <StaticImage
                        src="../images/campaign-creators-gMsnXqILjp4-unsplash.jpg"
                        alt="people sitting at table listening to presentation"
                        placeholder="blurred"
                      />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title">Leadership Development</p>
                      </div>
                    </div>
                    <div className="content">
                      <p className="has-text-left">Expand the capacity of individuals to perform in leadership roles within your organization. We partner together to custom design leadership development solutions for your organization’s context, with an interactive approach that sustains learning and has the greatest impact on performance.</p>
                      <Link to="/leadership-development" className="button is-multiline has-background-primary has-text-light has-text-weight-bold">Learn more about Leadership Development</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="card has-background-light has-text-centered">
                  <div className="card-image">
                    <figure className="image">
                      <StaticImage
                        src="../images/person-writing-on-notebook-669615.jpg"
                        alt="person writing on notebook beside laptop"
                        placeholder="blurred"
                      />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title">Talent & Performance Management</p>
                      </div>
                    </div>
                    <div className="content">
                      <p className="has-text-left">Identify, develop, engage and retain the most talented employees availabale in the job market. Our services include talent reviews, succession and career planning and performance management to ensure that top talent is leading your company.</p>
                      <Link to="/talent-performance-management" className="button is-multiline has-background-primary has-text-light has-text-weight-bold">Learn more about Talent & Performance Management</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    )
}
